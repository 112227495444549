<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="6" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ subjectName }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
              </v-row>
              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
              <v-col sm="6" cols="12" md class="text-center mb-8">
                <div class="text-h5">Result of Subject {{ subjectName }}</div>
              </v-col>
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listScore"
                        hide-default-footer
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th>{{ $t("student_id") }}</th>
                              <th>{{ $t("name_en") }}</th>
                              <th>{{ $t("name_kh") }}</th>
                              <th>{{ $t("attendance") }}</th>
                              <th>{{ $t("midterm") }}</th>
                              <th>{{ $t("final") }}</th>
                              <th>{{ $t("Total") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tr
                            v-for="(sess, index) in items"
                            v-bind:key="index"
                            class="text-center font-weight-black"
                            style="
                              height: 35px;
                              border: 1pt solid rgb(104, 102, 102) !important;
                            "
                          >
                            <td>{{ 1 + index++ }}</td>
                            <td>{{ sess.student_id }}</td>
                            <td>{{ sess.name_en }}</td>
                            <td>{{ sess.name_kh }}</td>
                            <td>
                              {{ sess.values.Attendance }}
                            </td>
                            <td>
                              {{ sess.values.Midterm }}
                            </td>
                            <td>
                              {{ sess.values.Final }}
                            </td>
                            <td class="text-bold">
                              {{ sess.totalScore }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import { getScoreByStudent } from "@schoolbase/web-client-lib";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
export default {
  name: "app",
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      id: "",
      token: store.getters.getToken,
      listScore: [],
      isLoading: false,
      myLoading: false,
      name_en: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      approval: "",
      subjectName: this.$route.params.name,
      rowID: "",
      stu_id: "",
      name_kh: "",
      headers: [
        { text: "No", value: "no" },
        { text: "Student ID", value: "student_id" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "No", value: "no" },
      ],
      listStudent: [],
      items: [
        {
          text: "Major",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Session",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Subject",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Score",
          disabled: true,
        },
      ],
    };
  },
  components: {
    Loading,
  },
  computed: {},
  methods: {
    async loadScore() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getScoreByStudent(
          this.$route.params.student_id,
          this.$route.params.id,
          this.token
        );
        this.listScore = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  async mounted() {
    this.loadScore();
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}
</style>
